<template>
  <Tabs v-model="tab" @on-click="getTab()" class="layout-config-activity h-100 p-10">
    <TabPane name="0" label="我创建的活动">
      <ActivityList v-if="tab == 0" />
    </TabPane>
    <TabPane name="1" label="我参加的活动">
      <ActivityJoin v-if="tab == 1" />
    </TabPane>
  </Tabs>
</template>

<script>
import ActivityList from './activity/ActivityList.vue'
import ActivityJoin from './activity/ActivityJoin.vue';
export default {
  components: { ActivityList, ActivityJoin }, 
  data() {
    return {
      tab: 0
    }
  },
  methods: {
    getTab() {
      this.$router.push({
          path: '/config/dataManage/activity',
          query: { tab: this.tab }
      })
    }
  },
  mounted() {
    let { tab } = this.$route.query
    this.$nextTick(() => {
        tab ? (this.tab = tab) : (this.tab = '0')
    })
  }
}
</script>

<style lang="less">
.layout-config-activity {
  position: relative;
  .ivu-tabs-content {
    width: 100%;
    height: calc(100% - 40px);
    position: absolute;
  }

}
</style>
